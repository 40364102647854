import { SVGProps, Ref, forwardRef } from 'react'

const SvgDigitalVault = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.182 1.874A22.45 22.45 0 0 0 12 0a22.45 22.45 0 0 0-9.182 1.874l-.012.005A2.237 2.237 0 0 0 1.5 3.925v-.002h.75l-.75.005v-.003 7.612A12.657 12.657 0 0 0 9.613 23.35l1.04.4.27-.7-.269.7a3.75 3.75 0 0 0 2.692 0l-.269-.7.27.7 1.04-.4A12.657 12.657 0 0 0 22.5 11.537V3.923h-.75l.75.005v-.003a2.236 2.236 0 0 0-1.306-2.046l-.012-.005ZM3.425 3.245A.737.737 0 0 0 3 3.918l-.75.005H3v7.614a11.157 11.157 0 0 0 7.151 10.413l-.269.7.27-.7 1.04.4c.52.2 1.096.2 1.616 0l1.04-.4.27.7-.269-.7A11.157 11.157 0 0 0 21 11.537V3.918a.737.737 0 0 0-.425-.673l.007.003.3-.687-.312.682.005.002A20.95 20.95 0 0 0 12.007 1.5h-.014a20.95 20.95 0 0 0-8.568 1.745Zm0 0-.007.003-.3-.687.312.682-.005.002ZM7.5 9a.75.75 0 0 0-.75.75v7.5c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75v-7.5A.75.75 0 0 0 16.5 9h-.75v-.75a3.75 3.75 0 0 0-7.5 0V9H7.5Zm.75 1.5h7.5v6h-7.5v-6Zm6-2.25V9h-4.5v-.75a2.25 2.25 0 1 1 4.5 0Zm-3.046 4.484a1.125 1.125 0 1 1 1.591 1.59 1.125 1.125 0 0 1-1.59-1.59Z"
      clipRule="evenodd"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgDigitalVault)
export default ForwardRef
